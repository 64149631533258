import React from 'react'

// import { Link } from 'gatsby'
import { AnchorLink as Link } from "gatsby-plugin-anchor-links";

import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { fontSans } from '../styles/vars.js'


const TocContainer = styled.div`
    background-color: hsla(270deg, 30%, 0%, 0.07) !important;
    padding: 1em;
    margin-bottom: 2em;
    border: 1px solid #ccc;
    font-family: ${fontSans};
    & p {
        margin-bottom: 0;
    }
    & a {
        color: inherit;
    }

    @media (max-width: 575px) {
        padding: 10px 15px;
        font-size: 0.85em;
    }
`

const TocList = ({ children }) => (
    <ul
        className='hack-to-avoid-matching-in-css-module'
        css={css`
        list-style: none;
        margin-bottom: 0.8rem;
        margin-left: -5px;
        padding-left: 0;
    `}
    >
        {children}
    </ul>
)


const TocItem = ({ tag, isFreebie, children, ...etc }) => {

    // To format different tags - although atm I'm only showing H2s
    // const style = {
    //     h2: ``,
    //     h3: `padding-left: 3rem;`
    // }
    //
    // ${style[tag]}  // put this in to the css below..

    return (
        <li
            css={css`
            margin-bottom: 1rem;
            padding-left: 2rem;
            background: url(/image/leaf.svg) no-repeat left 5px top 4px;
            ${isFreebie && `background: url(/image/freebie.svg) no-repeat left -2px top;`}
            &:last-child {
                margin-bottom: 0rem;
            }
`}
            {...etc}
        >
            {children}
        </li>
    )
}

const Toc = ({ tocData: tocDataIn, postUri }) => {

    const tocData = tocDataIn.filter(item => item.tag === 'h2')
    // If we need to do a hierarchical list then I think I'll need to sort it here
    // i.e. put h3s as children of h2 items
    // and potential recurse that..?

    return (
        <TocContainer>
            <h3>Table of Contents</h3>
            <TocList>
                {tocData.map(tocItem => (
                    <TocItem
                        key={tocItem.slug}
                        tag={tocItem.tag}
                        isFreebie={tocItem.isFreebie}
                    >
                        <Link
                            to={`${postUri.replace(/\/$/, '')}#${tocItem.slug}`}
                        >
                            {tocItem.text}
                        </Link>
                    </TocItem>
                ))}
            </TocList>
        </TocContainer>
    )
}


export default Toc