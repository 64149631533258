
// 'Sentence case for titles'
// - by which I mean basically sentence case, but with caps after colon or 1) 2) etc.
function titleSentenceCase(text) {

    if (!text) return text

    const result = text
        .replace(/\b[A-Z][a-z]+/g, match => match.toLowerCase())       // make words lower case
        //                                                         - I'm deliberately excluding all uppercase words, e.g. 'BBC'
        //                                                         - also will not touch vitamins, like 'B12'
        .replace(/\bi\b/g, 'I')                // capitalize word 'I'
        .replace(/\bA\b/g, 'a')                // lower case word 'a'
        .replace(/^[a-z]/, match => match.toUpperCase())              // caps first letter of whole
        .replace(/: [a-z]/g, (match) => match.toUpperCase())          // caps first letter after colon
        .replace(/[0-9][).] [a-z]/g, (match) => match.toUpperCase())    // caps first letter after 1), 2), .. ot 1, 2, ..

    return result;

}

// module.exports.titleSentenceCase = titleSentenceCase
export { titleSentenceCase };