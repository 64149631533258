import React from 'react';

import { hexAlphaToRgbaString } from '../utils/hexToRgb'

import * as style from './wave.module.css'


// use a *seedable* random num generator (so not Math.Random)
// From https://stackoverflow.com/a/19303725/11571725
let seed
function random(seedIn) {
    seed = seedIn || seed
    var x = Math.sin(seed++) * 10000;
    return x - Math.floor(x);
}


const Wave = ({
    waveColor = '#c194e4',     // mauve
    invert = false,            // for an 'end of section' wave - i.e. upside down
    alt = false,               // different paths, for variation
    variation = 0              // number, so can have diff waves for different places on page
}) => {

    const gradientStartColor = hexAlphaToRgbaString(waveColor, 0.3);
    const flipY = invert ? 'scale(1,-1) translate(0 -396)' : ''

    const gradientId = `gradient-${waveColor}`
    const gradientFill = `url(#${gradientId})`

    // Seed based on attribute of current page - so there's no danger of the waves wiggling when <Layout> is rerendered
    // (e.g. because user scrolled up).
    const seed = (typeof window === 'object' ? window.location.pathname.length : 1) + variation
    random(seed)

    // pick a random wave for each
    const pathA = Math.trunc(random() * wavePaths.length)
    const pathB = Math.trunc(random() * wavePaths.length)
    const pathC = Math.trunc(random() * wavePaths.length)

    const transformXwaveA = 'scale(1.2,1) translate(-100,0)'  // so don't all start on same Y coord
    const transformXwaveB = 'scale(1.4,1) translate(-500,0)'
    const transformXwaveC = 'scale(1.6,1) translate(-800,0)'

    return (
        <div className={style.waveContainer}>

            <div className={style.waveSingle} >

                <svg height="10%" width="100%" id="svg"
                    viewBox="0 0 1440 500"
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                >

                    <g transform={flipY}>
                        <g transform={transformXwaveA}>
                            <defs>
                                <linearGradient id={gradientId} gradientTransform="rotate(90)">
                                    <stop offset="22%" stopColor={gradientStartColor} />
                                    <stop offset="95%" stopColor={waveColor} />
                                </linearGradient>
                            </defs>
                            <path d={wavePaths[pathA]}
                                stroke="none" strokeWidth="0" fill={gradientFill} className="transition-all duration-300 ease-in-out delay-150">
                            </path>
                        </g>

                        <g transform={transformXwaveB}>
                            <defs>
                                <linearGradient id={gradientId} gradientTransform="rotate(90)">
                                    <stop offset="22%" stopColor={gradientStartColor} />
                                    <stop offset="95%" stopColor={waveColor} />
                                </linearGradient>
                            </defs>
                            <path d={wavePaths[pathB]}
                                stroke="none" strokeWidth="0" fill={gradientFill}
                            >

                            </path>
                        </g>
                        <g transform={transformXwaveC}>
                            <defs>
                                <linearGradient id={gradientId} gradientTransform="rotate(90)">
                                    <stop offset="22%" stopColor={gradientStartColor} />
                                    <stop offset="95%" stopColor={waveColor} />
                                </linearGradient>
                            </defs>
                            <path
                                d={wavePaths[pathC]}
                                stroke="none" strokeWidth="0" fill={gradientFill}
                            >
                            </path>
                        </g>
                    </g>
                </svg>
            </div>
        </div>
    )
}



const wavePaths = [
    "M 0,500 C 0,500 0,250 0,250 C 44.70109963288793,236.12282781352542 89.40219926577586,222.24565562705087 129,235 C 168.59780073422414,247.75434437294913 203.09230256978447,287.14020530532196 236,314 C 268.90769743021553,340.85979469467804 300.2285904550862,355.1935231516614 332,328 C 363.7714095449138,300.8064768483386 395.9933356098706,232.08570208803252 432,246 C 468.0066643901294,259.9142979119675 507.79806710543164,356.46366849620864 551,338 C 594.2019328945684,319.53633150379136 640.8143959684032,186.05962392713298 673,174 C 705.1856040315968,161.94037607286702 722.9443490209551,271.2978357952594 758,277 C 793.0556509790449,282.7021642047406 845.4082079477768,184.74903289182956 884,157 C 922.5917920522232,129.25096710817044 947.4228191879386,171.7060326374224 982,208 C 1016.5771808120614,244.2939673625776 1060.9005153004696,274.4268365584808 1097,296 C 1133.0994846995304,317.5731634415192 1160.9751196101836,330.58662112865466 1202,302 C 1243.0248803898164,273.41337887134534 1297.1990062587968,203.2266789269007 1344,213 C 1390.8009937412032,222.7733210730993 1430.2288553546296,312.5066631637427 1445,332 C 1459.7711446453704,351.4933368362573 1449.8855723226852,300.74666841812865 1440,250 C 1440,250 1440,500 1440,500 Z",
    "M 0,500 C 0,500 0,250 0,250 C 38.81252933960026,280.8268640295445 77.62505867920052,311.6537280590889 120,311 C 162.37494132079948,310.3462719409111 208.3122946227981,278.21195179318863 241,258 C 273.6877053772019,237.78804820681134 293.1257628296068,229.49846476815637 329,236 C 364.8742371703932,242.50153523184363 417.18465405877475,263.79418913418596 458,270 C 498.81534594122525,276.20581086581404 528.1356209352942,267.32477869509995 564,238 C 599.8643790647058,208.67522130490005 642.2728622000479,158.90669608541427 679,160 C 715.7271377999521,161.09330391458573 746.772930264514,213.0484369632429 779,234 C 811.227069735486,254.9515630367571 844.6354167418963,244.8995560616142 883,258 C 921.3645832581037,271.1004439383858 964.6854027679005,307.3533387903003 999,288 C 1033.3145972320995,268.6466612096997 1058.6229721865018,193.68708877718475 1092,209 C 1125.3770278134982,224.31291122281525 1166.822708486092,329.8983061009608 1204,330 C 1241.177291513908,330.1016938990392 1274.0861938691291,224.7196868189721 1322,187 C 1369.9138061308709,149.2803131810279 1432.8325160373918,179.22294662315085 1455,201 C 1477.1674839626082,222.77705337684915 1458.5837419813042,236.38852668842458 1440,250 C 1440,250 1440,500 1440,500 Z",
    "M 0,500 C 0,500 0,250 0,250 C 36.929807344176325,279.8463312844044 73.85961468835265,309.69266256880877 106,288 C 138.14038531164735,266.30733743119123 165.49134859076577,193.0756810091692 205,178 C 244.50865140923423,162.9243189908308 296.1749909485843,206.00461339451456 333,214 C 369.8250090514157,221.99538660548544 391.8086876148971,194.90586541277253 423,226 C 454.1913123851029,257.0941345872275 494.59025859182736,346.37192495439547 542,331 C 589.4097414081726,315.62807504560453 643.8302780177937,195.60643476964572 681,162 C 718.1697219822063,128.39356523035428 738.0886293369977,181.20233596702164 768,187 C 797.9113706630023,192.79766403297836 837.8152046342158,151.5842213622677 882,184 C 926.1847953657842,216.4157786377323 974.650552126139,322.4607785839076 1015,325 C 1055.349447873861,327.5392214160924 1087.582586861228,226.57266430210208 1117,185 C 1146.417413138772,143.42733569789792 1173.019100428949,161.24856420768398 1210,191 C 1246.980899571051,220.75143579231602 1294.3410114229753,262.433078867162 1338,269 C 1381.6589885770247,275.566921132838 1421.61685387915,247.01912032366798 1438,238 C 1454.38314612085,228.98087967633202 1447.191573060425,239.49043983816603 1440,250 C 1440,250 1440,500 1440,500 Z",
    "M 0,500 C 0,500 0,250 0,250 C 31.66008701077898,299.0368022319149 63.32017402155796,348.07360446382984 107,322 C 150.67982597844204,295.92639553617016 206.37939092454712,194.7423843765956 240,159 C 273.6206090754529,123.2576156234044 285.16226228025363,152.9568580297878 314,202 C 342.83773771974637,251.0431419702122 388.9715599544384,319.4301835042531 434,325 C 479.0284400455616,330.5698164957469 522.9514979019929,273.32240795319996 555,232 C 587.0485020980071,190.67759204680004 607.2224484375901,165.28018468294704 648,195 C 688.7775515624099,224.71981531705296 750.1587083476469,309.556853315012 794,311 C 837.8412916523531,312.443146684988 864.1427181718225,230.49240205700494 891,231 C 917.8572818281775,231.50759794299506 945.2704189650631,314.47353845696836 987,330 C 1028.729581034937,345.52646154303164 1084.7756059679252,293.61344411512175 1119,285 C 1153.2243940320748,276.38655588487825 1165.6271571632365,311.07268508254464 1200,333 C 1234.3728428367635,354.92731491745536 1290.7157653791287,364.0958155546996 1340,331 C 1389.2842346208713,297.9041844453004 1431.509781320249,222.54405269865728 1447,202 C 1462.490218679751,181.45594730134272 1451.2451093398754,215.72797365067134 1440,250 C 1440,250 1440,500 1440,500 Z",
    "M 0,500 C 0,500 0,250 0,250 C 25.153570468388438,231.84613628890727 50.307140936776875,213.69227257781455 94,226 C 137.69285906322312,238.30772742218545 199.9250067212809,281.077045977649 240,280 C 280.0749932787191,278.922954022351 297.9928321780997,233.99954351158956 323,208 C 348.0071678219003,182.00045648841044 380.1036645663203,174.92477997599283 427,200 C 473.8963354336797,225.07522002400717 535.5925095566189,282.30133658443924 573,304 C 610.4074904433811,325.69866341556076 623.5262972072039,311.86987368625023 656,322 C 688.4737027927961,332.13012631374977 740.3023016145654,366.21916867055995 785,326 C 829.6976983854346,285.78083132944005 867.2644963345343,171.25345163151002 900,165 C 932.7355036654657,158.74654836848998 960.6397130472974,260.7670248034 995,296 C 1029.3602869527026,331.2329751966 1070.1766514762767,299.67844915489 1114,296 C 1157.8233485237233,292.32155084511 1204.653681047596,316.5191785770398 1237,283 C 1269.346318952404,249.48082142296022 1287.2086243333395,158.24483653695086 1322,169 C 1356.7913756666605,179.75516346304914 1408.5118216190458,292.5014752751569 1431,323 C 1453.4881783809542,353.4985247248431 1446.744089190477,301.7492623624215 1440,250 C 1440,250 1440,500 1440,500 Z",
    "M 0,500 C 0,500 0,250 0,250 C 38.16804094660162,232.20140575265333 76.33608189320324,214.4028115053067 112,219 C 147.66391810679676,223.5971884946933 180.82371337378862,250.5901597314267 219,277 C 257.1762866262114,303.4098402685733 300.36906461164216,329.23654956898633 339,328 C 377.63093538835784,326.76345043101367 411.70002817964274,298.46364199262774 440,297 C 468.29997182035726,295.53635800737226 490.8308226697868,320.9088824605025 535,307 C 579.1691773302132,293.0911175394975 644.9766811412102,239.90082816536213 681,233 C 717.0233188587898,226.09917183463787 723.2624527653726,265.4878048780488 757,286 C 790.7375472346274,306.5121951219512 851.9735077972989,308.1479523224427 893,305 C 934.0264922027011,301.8520476775573 954.8435160454313,293.92038583218044 984,278 C 1013.1564839545687,262.07961416781956 1050.6524280209755,238.1705043488356 1095,242 C 1139.3475719790245,245.8294956511644 1190.5467718706666,277.397596772477 1231,274 C 1271.4532281293334,270.602403227523 1301.160484496359,232.2391085612562 1342,228 C 1382.839515503641,223.7608914387438 1434.8112901438974,253.64596898249823 1453,263 C 1471.1887098561026,272.35403101750177 1455.5943549280514,261.17701550875086 1440,250 C 1440,250 1440,500 1440,500 Z",
    "M 0,500 C 0,500 0,250 0,250 C 39.949560551552466,291.4280614463379 79.89912110310493,332.8561228926757 115,327 C 150.10087889689507,321.1438771073243 180.35307613913272,268.00356987563487 222,268 C 263.6469238608673,267.99643012436513 316.688574340364,321.1295976047849 350,310 C 383.311425659636,298.8704023952151 396.8926264994111,223.47803970522568 432,212 C 467.1073735005889,200.52196029477432 523.7409196619915,252.9582435743124 571,283 C 618.2590803380085,313.0417564256876 656.1436948526231,320.6889859975246 685,325 C 713.8563051473769,329.3110140024754 733.6843009275163,330.2858124355892 764,321 C 794.3156990724837,311.7141875644108 835.1191014373115,292.16776426011876 875,252 C 914.8808985626885,211.83223573988124 953.8392933232371,151.04313052393564 998,167 C 1042.1607066767629,182.95686947606436 1091.5237252697402,275.6597136441388 1127,308 C 1162.4762747302598,340.3402863558612 1184.0658055978015,312.3180148995092 1211,284 C 1237.9341944021985,255.68198510049078 1270.2130523390533,227.06822675782445 1315,229 C 1359.7869476609467,230.93177324217555 1417.0819850459848,263.409078069193 1440,272 C 1462.9180149540152,280.590921930807 1451.4590074770076,265.2954609654035 1440,250 C 1440,250 1440,500 1440,500 Z",
    "M 0,500 C 0,500 0,250 0,250 C 34.87666391728921,278.5529909461315 69.75332783457841,307.105981892263 108,288 C 146.2466721654216,268.894018107737 187.8633525789756,202.1290633770795 230,211 C 272.1366474210244,219.8709366229205 314.79326184951935,304.37776459941915 346,310 C 377.20673815048065,315.62223540058085 396.96360002294705,242.35987822524388 430,247 C 463.03639997705295,251.64012177475612 509.35233805869245,334.1827224996052 544,339 C 578.6476619413075,343.8172775003948 601.6270477422833,270.9092317763354 646,263 C 690.3729522577167,255.0907682236646 756.1394709721745,312.18035039505327 795,293 C 833.8605290278255,273.81964960494673 845.8150683690187,178.36936664345163 880,175 C 914.1849316309813,171.63063335654837 970.6002555517507,260.3421830311403 1014,259 C 1057.3997444482493,257.6578169688597 1087.7839094239782,166.26190123198725 1118,179 C 1148.2160905760218,191.73809876801275 1178.264106752336,308.61021204091065 1209,309 C 1239.735893247664,309.38978795908935 1271.1596635666788,193.29725060437022 1313,166 C 1354.8403364333212,138.70274939562978 1407.097238980949,200.20078554160852 1430,229 C 1452.902761019051,257.7992144583915 1446.4513805095255,253.89960722919574 1440,250 C 1440,250 1440,500 1440,500 Z",

]



export default Wave;
