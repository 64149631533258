/**
 * Creates hierarchical menu based on WordPress menu.
 * @link https://www.wpgraphql.com/docs/menus/#hierarchical-data
 */
import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import UniversalLink from "../utils/UniversalLink"
import { FlatListToHierarchical } from "../utils/FlatListToHierarchical"
import { FaBars as HamburgerIcon } from "react-icons/fa"

import * as style from "./header.module.css"
import Overlay from "./overlay"
import AppropriateCaret from "./appropriateCaret"
import { titleCase } from "../utils/titleCase"

const ItemWithSubmenu = ({ menuItem, isOpen, setSubmenuOpen }) => {

    const { title } = menuItem

    const onClick = () => {
        setSubmenuOpen(isOpen ? null : title)
    }

    return (
        <button
            className={isOpen ? 'active' : ''}
            onClick={onClick}
        >
            {title}
            <AppropriateCaret isOpen={isOpen} />
        </button>
    )
}


const MenuLoop = ({ menuItems, submenuOpen, setSubmenuOpen, isPhone }) => {
    return (
        <nav>
            <ul>
                {menuItems.map((menuItem, index) => {
                    return (
                        <li
                            key={index}
                            className={
                                (menuItem.routes.length > 0 ? "has-submenu" : '')  // ??? has-submenu not actually used
                                + (!!menuItem.parent ? "submenu-item" : '')
                            }
                        >
                            {(menuItem.routes.length > 0) && isPhone
                                ? (
                                    <React.Fragment>
                                        <ItemWithSubmenu
                                            menuItem={menuItem}
                                            isOpen={submenuOpen === menuItem.title}
                                            setSubmenuOpen={setSubmenuOpen}
                                        />
                                        {submenuOpen === menuItem.title ?
                                            <MenuLoop menuItems={menuItem.routes} />
                                            : null
                                        }
                                    </React.Fragment>
                                )
                                : (
                                    <UniversalLink to={menuItem.path} activeClassName="current-page">
                                        {menuItem.parent ? titleCase(menuItem.title) : menuItem.title}
                                    </UniversalLink>
                                )
                            }
                        </li>
                    )
                })}
            </ul>
        </nav>
    )
}

const MainNav = () => {
    const wpMenu = useStaticQuery(graphql`
    {
      allWpMenuItem(
        sort: { fields: order, order: ASC }
        filter: {
          menu: { node: { slug: { eq: "top-navigation" } } }
          # parentDatabaseId: { eq: 0 }
        }
      ) {
        nodes {
          id
          title: label
          path
          target
          parent: parentId
        }
      }
    }
  `)

    const headerMenu = FlatListToHierarchical(wpMenu.allWpMenuItem.nodes, {
        idKey: "id",
        childrenKey: "routes",
        parentKey: "parent",
    })

    const [phoneMenuOpen, setPhoneMenuOpen] = useState(false)
    const togglePhoneMenu = () => {
        setPhoneMenuOpen(!phoneMenuOpen);
    }

    const [submenuOpen, setSubmenuOpen] = useState(null)

    return (
        <React.Fragment>
            <nav className={style.mainNav}>
                {headerMenu.length > 0 && (
                    <MenuLoop menuItems={headerMenu} />
                )}
            </nav>
            <nav className={style.phoneHamburgerContainer}>
                <button
                    className={`${style.hamburgerButton} ${phoneMenuOpen ? 'pressed' : ''}`}
                    onClick={togglePhoneMenu}
                >
                    <HamburgerIcon style={{ verticalAlign: '-10%' }} />
                </button>
            </nav>
            {phoneMenuOpen && (
                <React.Fragment>
                    <div className={style.phoneNav}>
                        {headerMenu.length > 0 && (
                            <MenuLoop
                                menuItems={headerMenu}
                                submenuOpen={submenuOpen}
                                setSubmenuOpen={setSubmenuOpen}
                                isPhone
                            />
                        )
                        }
                    </div>
                    <Overlay dismiss={() => setPhoneMenuOpen(false)} />
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

export default MainNav


/*
TO DOs:
o non-phone menus should be expandable too
  - but will need to think about positioning.. (popper?? dropdowns?)
o maybe make the expansion nicer (CSSTransition)

*/