import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import React from "react"

import MainNav from "./mainNav"

import * as style from './header.module.css';


const Header = () => {

    const data = useStaticQuery(
        graphql`
        {
    logo: file(name: {eq: "efm-logo"}) {
        childImageSharp {
            fixed(width: 44) {
            ...GatsbyImageSharpFixed
            }
        }
    }
#    brandImage: file(name: {eq: "ecofeminist-mama"}) {
#        childImageSharp {
#            fixed(width: 240) {
#            ...GatsbyImageSharpFixed
#            }
#        }
#    }
}
    `
    )

    return (

        <header className={style.header}>
            <div className={style.brandContainer}>
                <Link to="/">
                    <div className={style.brand}>
                        <Img
                            fixed={data.logo.childImageSharp.fixed}
                            alt='leaf logo'
                        />
                        {/* <Img
                            fixed={data.brandImage.childImageSharp.fixed}
                            alt='ecofeminist mama'
                            style={{
                                width: 250,
                                height: 25,
                            }}
                        /> */}
                        <img
                            className={style.logoText}
                            src={`/image/ecofeminist-mama.svg`}
                            alt='logo'
                            width='240'
                            height='24'
                        />
                    </div>
                </Link>
            </div>
            <MainNav />
        </header>
    )
}

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default React.memo(Header)
