import React, { useRef, useEffect } from 'react';

import { css } from '@emotion/react';


const Overlay = ({ dismiss }) => {

    const overlayRef = useRef(null);

    useEffect(() => {
        // dismiss on scroll on touch screen
        const handleTouch = (e) => {
            e.preventDefault();
            dismiss();
        }

        const overlayDiv = overlayRef.current;
        overlayDiv && overlayDiv.addEventListener('touchstart', handleTouch);  // generates a lint warning..?

        return function tidyup() {
            overlayDiv && overlayDiv.removeEventListener('touchstart', handleTouch)
        }
    });

    return (
        <div
            css={css`
            position: fixed;
            top: 64px;
            left: 0;
            width: 100%;
            height: calc(100% - 64px);
            margin: 0;
            z-index: 999;
            // background-color: rgba(0,0,0,0.6);
        `}
            onClick={dismiss}
            ref={overlayRef}
        />
    );

}

export default Overlay;