function titleCase(text) {

    const smallWords = 'the|and|nor|or|yet|so|a|amid|an|apud|as|at|atop|but|by|down|for|from|in|into|like|mid|near|next|of|off|on|onto|out|over|pace|past|per|plus|pro|qua|sans|save|than|till|to|unto|up|upon|via|vice|vs.|with'

    const smallRegExp = new RegExp(`\\b(${smallWords})\\b`, 'gi')

    const result = text
        .replace(/\b[a-z][a-z]+\b/g, match => `${match[0].toUpperCase()}${match.slice(1)}`)  // words 1st letter upper
        //                                                           - I'm deliberately excluding all uppercase words, e.g. 'BBC'
        //                                                           - also will not touch vitamins, like 'B12'
        .replace(/\bi\b/g, 'I')                                    // capitalize word 'I'
        .replace(smallRegExp, match => match.toLowerCase())        // small words
        .replace(/^[a-z]/, match => match.toUpperCase())           // caps first letter of whole thing
        .replace(/: [a-z]/g, match => match.toUpperCase())         // caps first letter after colon

    return result;

}

// module.exports.titleCase = titleCase
export { titleCase }
