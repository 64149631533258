/**
 * Seo component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function Seo(props) {

  //   {
  //   description,
  //   lang,
  //   title,
  //   seo = {},
  //   // ogImageUrl = `http://gatsby.ecofeministmama.com/wp-content/uploads/2021/04/decor-decoration-704147-20210405.jpg`
  //   ogImageUrl = `http://gatsby.ecofeministmama.com/wp-content/uploads/2021/04/decor-decoration-704147-20210412-ogimage.jpg`
  // }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const description = props.description || props.seo?.metaDesc || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title
  const title = (props.title || defaultTitle) === defaultTitle ? defaultTitle : `${props.title} | ${defaultTitle}`
  const ogTitle = props.ogTitle || title
  const ogImageUrl = props.ogImageUrl || `http://gatsby.ecofeministmama.com/wp-content/uploads/2021/04/decor-decoration-704147-20210412-ogimage.jpg`

  return (
    <Helmet
      htmlAttributes={{
        lang: props.lang,
      }}
      title={title}
      meta={[
        { property: `og:title`, content: ogTitle },
        { name: `description`, content: description },
        { property: `og:description`, content: description },
        { property: `og:type`, content: `website` },
        { property: `og:image`, content: ogImageUrl },
        { name: `twitter:card`, content: `summary` },
        { name: `twitter:creator`, content: site.siteMetadata?.author || `` },
        { name: `twitter:title`, content: title },
        { name: `twitter:description`, content: description },
      ].filter(el => !!el)
      }
    >
      {/* Affiliate link scripts.. */}
      <script src="https://www.dwin2.com/pub.680487.min.js"></script>
      <script type="text/javascript" src="https://s.skimresources.com/js/212641X1692999.skimlinks.js"></script>

    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
