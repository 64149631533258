import React from 'react'
import parse, { domToReact } from 'html-react-parser'
import { titleCase } from './titleCase'
import { titleSentenceCase } from './titleSentenceCase'
import chalk from 'chalk'
import kebabCase from 'lodash/kebabCase'

import Toc from '../components/toc'


const tagActions = {
    h1: text => titleCase(text),
    h2: text => titleSentenceCase(text),
    h3: text => titleSentenceCase(text),
}
const tagsToFix = Object.keys(tagActions)


function setCaseSingleTag(tag, text) {
    const res = tagActions[tag](text)
    return res;
}
// module.exports.setCaseSingleTag = setCaseSingleTag;
export { setCaseSingleTag }

function processHtml(htmlString, title, postUri) {

    if (!htmlString) return;

    // Ignore certain bits of inline styling.
    // E.g. Some posts contain font-size 16px on some tags (e.g. <p> and <li>).
    let result = htmlString.replace(/font-size: 16px/g, '')

    let tocData = []

    const reactPage = parse(result,
        {
            replace: domNode => {
                const { name: tag, type, children } = domNode
                // console.log(getTextContent(domNode))
                if (type === 'tag' && tagsToFix.includes(tag)) {

                    if (children.length === 0) {
                        console.log(`❗️ ${tag} node with no children - not replaced!`, `in: ${title}`)
                        console.log(domNode)
                    }
                    else if (children.length > 1) {
                        // This happens if e.g. part of the heading is italic or bold, so it would be very 
                        // complicated to correct the capitalisation here (just do it manually in WordPress).
                        console.log(`⚠️ ${tag} node with >1 child - not replaced!`, `in: ${title}`)
                        console.log(chalk.white(`"${getTextContent(domNode)}"`))

                        // ??? this is almost same as below - prob should restructure this file..
                        // ..However we can append an id so that ToC will be able to jump to it:
                        if (domNode.parent === null) {   // top-level headings only (exclude 'Download your free...' headings)
                            const textFixed = tagActions[tag](getTextContent(domNode))
                            const slug = kebabCase(textFixed)
                            tocData.push({ tag, text: textFixed, slug })
                            const el = React.createElement(tag, { id: slug }, domToReact(children))
                            return el;
                        }
                    }

                    else {
                        const text = children[0].type === 'text'
                            ? children[0].data                   // it's a text node
                            : children[0].children[0].data       // try assuming it's a <b> and get text from *its* child

                        if (!text) {
                            console.log(`❗️ ${tag} node with non-text child - not replaced!`, `in: ${title}`)
                            console.log(chalk.white(`"${getTextContent(domNode)}"`))
                        }
                        else {
                            const textFixed = tagActions[tag](text)
                            const slug = kebabCase(textFixed)
                            if (!tocData.some(tocItem => tocItem.slug === slug)) {
                                tocData.push({
                                    tag,
                                    isFreebie: !!domNode.parent,
                                    text: textFixed,
                                    slug,
                                })
                                const el = React.createElement(tag, { id: slug }, textFixed)
                                return el;
                            }
                        }
                    }
                }

                // replace 'short codes'
                // console.log(domNode)
                // if (type === 'tag' && tag === 'p' && children[0].data?.match(/posts-grid-minihubs/)) {
                //     console.log('shortcode', domNode)
                //     return <PostsGridEachCateg />
                // }

                if (type === 'tag' && children.length === 1) {
                    const text = children[0].data
                    if (text === '[TOC]') {
                        const el = React.createElement(Toc, { id: 'table-of-contents', tocData, postUri }, null)
                        return el;
                    }
                }
            }
        }
    )

    return reactPage;

}

export { processHtml }

function getTextContent(node) {
    if (!node) return null;
    else if (node.type === 'text') return node.data
    else if (Array.isArray(node.children)) {
        return node.children.reduce(
            (acc, child) => {
                return `${acc}${getTextContent(child)}`
            },
            ''
        )
    }

}