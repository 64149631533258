import React from 'react'
import { Link } from "gatsby"
import Img from "gatsby-image"

import * as style from './postsGrid.module.css';
import { setCaseSingleTag } from '../utils/processHtml';

const PostsGrid = ({ posts }) => {

    return (

        <div className={style.articlesGrid}>

            {posts.map((post, i) => {

                return (

                    <article  // ??? this should be a plain div (or poss. section)
                        key={post.uri}
                    >

                        {post.featuredImage && (
                            <div className={style.imageWrapper}>
                                <Link to={post.uri}>
                                    <Img
                                        fluid={post.featuredImage.node.localFile.childImageSharp.fluid}
                                        // fixed={post.featuredImage.node.localFile.childImageSharp.fixed}
                                        alt={post.featuredImage.node.altText}
                                        // objectFit='scale-down'
                                        className={style.pinImage}
                                    />
                                </Link>
                            </div>
                            // <figure className={style.pinImageOld}>
                            //     <Link to={post.uri}>
                            //         <Img
                            //             // fluid={post.featuredImage.node.localFile.childImageSharp.fluid}
                            //             fixed={post.featuredImage.node.localFile.childImageSharp.fixed}
                            //             alt={post.featuredImage.node.altText}
                            //         />
                            //     </Link>
                            // </figure>
                        )}

                        {/* <Catlist postObject={post} /> */}

                        <h4>
                            <Link to={post.uri}>
                                {setCaseSingleTag('h1', post.title)
                                    // not *actually* an h1 but should be capitalized same as post title in post header (i.e. h1)
                                }
                            </Link>
                        </h4>

                        <div className={style.pinImageMeta}>
                            by {post.author.node.name}
                            {' / '}
                            {new Date(post.date).toLocaleDateString("en-US", {
                                month: "short",
                                day: "numeric",
                                year: "numeric",
                            })}{" "}
                        </div>

                        <div className={style.excerpt} dangerouslySetInnerHTML={{ __html: post.excerpt }} />

                        <Link to={post.uri} className={style.readMore}>Read more {'\u00bb'}</Link>

                        {/* <div
                className={style.article__content}
                dangerouslySetInnerHTML={{ __html: post.excerpt }}
            /> */}
                        {/* <div className={style.article__tax}>
                Tagged:{" "}
                {post.tags.nodes.map((tag, index) => [
                    index > 0 && ", ",
                    <Link key={index} to={tag.link}>
                        {tag.name}
                    </Link>,
                ])}
            </div> */}

                    </article>
                )
            }
            )}
        </div>

    )
}

export default PostsGrid;
