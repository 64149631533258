import React from 'react';

import {
    BsChevronUp as ChevronUpIcon,
    BsChevronDown as ChevronDownIcon,
} from 'react-icons/bs';


function AppropriateCaret({ isOpen }) {
    return <div className='appropriate-caret'>
        {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
    </div>
}


export default AppropriateCaret;