import React from "react"

import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import FooterNav from "./footerNav"
import Wave from "./wave";

// import {AiFillInstagram } from 'react-icons/ai';
import {
    FaInstagramSquare as InstagramIcon,
    FaPinterest as PinterestIcon,
    FaEnvelope as MailIcon
} from 'react-icons/fa';

// import BackgroundImage from 'gatsby-background-image';  // ??? remove dependency
import UniversalLink from "../utils/UniversalLink";

import * as style from "./footer.module.css"


const Footer = () => {

    const data = useStaticQuery(
        graphql`
        {
    footerImage: file(name: {eq: "footer-image"}) {
        childImageSharp {
            fluid(maxWidth: 440) {
            ...GatsbyImageSharpFluid
            }
        }
    }
    brandImage: file(name: {eq: "ecofeminist-mama"}) {
        childImageSharp {
            fixed(width: 278) {
            ...GatsbyImageSharpFixed
            }
        }
    }
}
    `
    )

    const { footerImage, brandImage } = data;

    return (

        <React.Fragment>
            <Wave waveColor='#a7dfa5' />
            <footer className={style.footer}>

                <div
                    className={style.bgImage}
                >
                    <Img
                        fluid={footerImage.childImageSharp.fluid}
                    />
                </div>

                {/* <img
                    className={style.bgImage}
                    src={`/image/footer-image.png`}
                // height="300px"
                // ???image - bg
                // do this better
                // i.e. use gatsby plugins
                // use gatsby-background-image?
                // also get the image from WP instead of static files
                // ..or at least run it through sharp via graphqlq
                // nb. check sizings in css...
                /> */}

                <div className={style.main}>

                    <FooterNav />
                    <div >
                        <ul>
                            <li>
                                <Link to="/about-me/">About Ecofeminist Mama</Link>
                            </li>
                            <li>
                                <Link to="/disclosure/">Disclosure &amp; Terms of Use</Link>
                            </li>
                            <li>
                                <Link to="/contact/">Contact</Link>
                            </li>
                            <li>
                                <div className={style.icons}>
                                    <UniversalLink to="https://www.instagram.com/ecofeministmama/">
                                        <InstagramIcon />
                                    </UniversalLink>
                                    <UniversalLink to="https://www.pinterest.co.uk/ecofeministmama/">
                                        <PinterestIcon />
                                    </UniversalLink>
                                    <UniversalLink to="mailto:gudrun@ecofeministmama.com">
                                        <MailIcon />
                                    </UniversalLink>
                                </div>
                            </li>
                        </ul>

                    </div>

                </div>

                <div
                    className={style.bottom}
                >
                    <Img
                        fixed={brandImage.childImageSharp.fixed}
                        alt='ecofeminist mama'
                    />
                    <div>
                        © {new Date().getFullYear()} Ecofeminist Mama
                    </div>
                </div>

            </footer >

        </React.Fragment>
    )
}

export default React.memo(Footer)
