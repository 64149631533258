import { useState, useEffect, useRef, useCallback } from "react"
import throttle from 'lodash/throttle'


// pass in callback to do something on scrollup/down (gets called with true/false for up/down)
// or take return value (true/false)
export const useDetectScrollUp = (callback) => {

    const [didScrollUp, setDidScrollUp] = useState(false)
    const scrollY = useRef(0)         // changed from state to ref cos I was getting multiple unnec. renders

    const handleScroll = useCallback(
        () => {
            // console.log(scrollY.current)

            // if (window.pageYOffset < scrollY.current && !didScrollUp) {
            if (window.pageYOffset < scrollY.current) {
                setDidScrollUp(true)
                callback && callback(true)
            }
            // else if (window.pageYOffset > scrollY.current && didScrollUp) {
            else if (window.pageYOffset > scrollY.current) {
                setDidScrollUp(false)
                callback && callback(false)
            }
            scrollY.current = window.pageYOffset;
        },
        [callback]
    )

    useEffect(() => {

        const wrappedHandler = throttle(handleScroll, 50)

        console.log('addEventListener')
        window.addEventListener('scroll', wrappedHandler);

        return function tidyup() {
            window.removeEventListener('scroll', wrappedHandler);
        }
    }, [handleScroll]);

    // console.log(window.pageYOffset, didScrollUp)
    return didScrollUp

}
