import React, { useState } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import Header from "./header"
import Footer from "./footer"
import TransientPhoneHeader from "./transientPhoneHeader"
import CookieConsent from "react-cookie-consent"
import { fontSans } from '../styles/vars'
import { useDetectScrollUp } from "../utils/useDetectScrollUp"


const Layout = ({ children }) => {

    const [showTransientPhoneHeader, setShowTransientPhoneHeader] = useState(false)
    useDetectScrollUp(setShowTransientPhoneHeader)

    return (
        <React.Fragment>
            <Header />
            <TransientPhoneHeader
                show={showTransientPhoneHeader}
            />

            <div>
                <main>{children}</main>
            </div>
            <Footer />
            <CookieConsent
                // debug
                sameSite='lax'
                contentClasses="cookie-consent-text"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontFamily: fontSans,
                    fontSize: '16px',
                    color: '#aaa'
                }}
                buttonStyle={{
                    backgroundColor: '#1c5e08',
                    color: '#aaa',
                    fontSize: '1em'
                }}
            >
                By using this website, you agree to our use of cookies. We use cookies to provide you with a great experience, analyse our performance and to help our website run effectively. <Link to="/disclosure/">Read our full Disclaimer</Link> for more information.
            </CookieConsent>
        </React.Fragment>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
