import React from 'react'

import styled from '@emotion/styled'
import Header from './header'

import { colorBg } from '../styles/vars'
import { CSSTransition } from 'react-transition-group'


const PhoneHeaderContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 64px;
    z-index: 1000;
    border-bottom: 1px solid rgba(0,0,0,0.15);
    // opacity: 0.5;
    background-color: #fafaec;  //${colorBg};
    box-shadow: 0px 1px 3px rgba(0,0,0,0.05);

    @media (min-width: 1002px) {
        display: none;
    }
`


const TransientPhoneHeader = ({ show }) => {

    return (
        <CSSTransition
            classNames="slide-in-from-top"
            in={show && (window.pageYOffset > 500)}  // near top of page they can just get to the top menu, don't need transient one
            timeout={900}
            unmountOnExit
        >
            <PhoneHeaderContainer>
                <Header />
            </PhoneHeaderContainer>
        </CSSTransition>
    )
}

export default TransientPhoneHeader
